<template>
  <div class="loading">
    <Logo
      :size="200"
      style="padding-bottom: 2em;"
    />
    <v-progress-linear
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
import Logo from '@/components/Shared/Logo.vue';

export default {
  props: {
    size: {
      type: Number,
    },
  },
  components: {
    Logo,
  },
  async mounted() {
    try {
      this.$router.push(this.$route.query.redirect || '/portfolio');
    } catch (error) {
      this.$notify.error(`Couldn't connect to the server: ${error.message}`);
      this.$store.dispatch('auth/signout');
    } finally {
      await this.$store.dispatch('application/loading', false);
    }
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
